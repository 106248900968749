import React from "react";
import { mapOrderStatus } from "../../../actions/orderAction";
import { Box, Divider, Typography } from "@material-ui/core";
import { buttonColor } from "../../../style";
import moment from "moment";
import { CURRENCY } from "../../common/Constants";
import sup from "superscript-text";
import useNav from "../../../hooks/useNav";
import { roundToDecimalPlaces } from "../../../utils/stringUtils";
import SecondaryButtonV2 from "../../buttons/SecondaryButtonV2";
import { GiftInterface } from "../../../models/gift";
import PrimaryButtonV3 from "components/buttons/PrimaryButtonV3";
interface OrderHistoryProps {
  order: any;
  styles?: Record<
    "contentStyle" | "flexContainer" | "hover" | "empty" | "removeButtonStyle" | "removeButtonStyleDeclineGift",
    string
  >;
  isDesktop: boolean;
  onRedeem: (orderId) => void;
}

interface IActionButtonsProps {
  goTo: (url: string) => void;
  order: GiftInterface;
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ order, goTo }: IActionButtonsProps) => {
  return (
    <>
      <SecondaryButtonV2
        text="See details"
        style={{
          color: buttonColor.PINK,
          fontSize: "14px",
          margin: "auto",
          minWidth: "150px",
          height: "auto",
          fontWeight: 700
        }}
        handleClick={() => {
          goTo(`/profile/gift-details/${order.order_id}`);
        }}
      />
      {order.tracking_data.tracking_url && (
        <a
          href={order.tracking_data.tracking_url}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none"
          }}
        >
          <SecondaryButtonV2
            text="Track my Order"
            style={{
              color: buttonColor.PINK,
              fontSize: "14px",
              margin: "auto",
              minWidth: "150px",
              height: "auto",
              fontWeight: 700,
              marginTop: 18
            }}
          />
        </a>
      )}
    </>
  );
};
export default function PerMyGift({ order, styles, isDesktop, onRedeem }: OrderHistoryProps): ReturnType<React.FC> {
  const { goTo } = useNav();
  const mappedStatus = mapOrderStatus(order.claim_status, order.canceled);

  const redeem = () => {
    onRedeem(order.order_id);
  }
  return (
    <>
      {isDesktop ? (
        <>
          <tr>
            <td className={styles.hover} style={{ verticalAlign: "top" }}>
              <Box display="flex" flexDirection="row" paddingTop={"23px"}>
                <Box minWidth="100px">
                  <img
                    src={order.gift.image_url}
                    alt={order.gift.name}
                    style={{
                      alignSelf: "center",
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px",
                      objectFit: "cover"
                    }}
                  />
                </Box>
                <Box minWidth="180px" paddingLeft="28px" paddingRight="28px">
                  {order.gift.brand && (
                    <Typography
                      style={{
                        fontWeight: "bold",
                        fontSize: "14px"
                      }}
                    >
                      {order.gift.brand}
                    </Typography>
                  )}
                  <Typography
                    style={{
                      fontSize: "14px"
                    }}
                  >
                    {order.gift.name}
                  </Typography>
                  <Typography style={{ fontSize: "14px" }}>
                    {moment(order.date_ordered).format("MM/DD/YYYY")}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "bold"
                    }}
                  >
                    {CURRENCY}
                    {roundToDecimalPlaces(order.pricing.total_price_paid_converted, 2).split(".")[0]}.
                    <span style={{ fontWeight: 500 }}>
                      {sup(roundToDecimalPlaces(order.pricing.total_price_paid_converted, 2).split(".").pop())}
                    </span>
                  </Typography>
                </Box>
              </Box>
            </td>
            <td style={{ minWidth: "200px", verticalAlign: "top" }}>
              <Box paddingTop={"23px"}>
                {order.buyer_id !== process.env.REACT_APP_GUEST_CHECKOUT_USER_ID && (
                  <Typography style={{ fontSize: "14px" }}>{order.buyer_nickname}</Typography>
                )}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: mappedStatus.color
                  }}
                >
                  {mappedStatus.label}
                </Typography>
                <Box display={"inline-flex"} flexDirection={"row"}>
                  <img
                    src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${order.channel_partner_data.channel_partner_url}`}
                    alt=""
                    style={{
                      alignSelf: "center",
                      width: "16px",
                      height: "16px",
                      borderRadius: "10px"
                    }}
                  />
                  <Typography variant="body2" style={{ marginLeft: 8 }}>
                    {order.channel_partner_data.channel_partner_store_name}
                  </Typography>
                </Box>
                {order.message.length > 0 && (
                  <Box style={{ paddingTop: "16px" }}>
                    <Typography style={{ fontWeight: "bold" }}>Gift Message</Typography>
                    <Typography>{order.message}</Typography>
                  </Box>
                )}
              </Box>
            </td>
            <td style={{ verticalAlign: "top" }}>
              <Box paddingTop={"23px"}>
                <ActionButtons goTo={goTo} order={order} />
                {(order.claim_status ?? "").toLowerCase() === "unclaimed" && !order.canceled ? (
                  <PrimaryButtonV3
                    style={{
                      marginTop: "16px",
                      borderRadius: "24px",
                      fontSize: "14px",
                      minWidth: "150px",
                      height: "32px",
                      maxWidth: "150px",
                      fontWeight: 700
                    }}
                    handleClick={redeem}
                  >
                    {"Redeem"}
                  </PrimaryButtonV3>
                ) : null}
              </Box>
            </td>
          </tr>
          <tr>
            <td colSpan={3}>
              <Divider orientation={"horizontal"} variant={"fullWidth"} style={{ marginTop: "23px" }} />
            </td>
          </tr>
        </>
      ) : (
        <>
          <Box display="flex" flexDirection="row" paddingTop={"23px"}>
            <Box minWidth="100px">
              <img
                src={order.gift.image_url}
                alt={order.gift.name}
                style={{
                  alignSelf: "center",
                  width: "100px",
                  height: "100px",
                  borderRadius: "10px",
                  objectFit: "cover"
                }}
              />
            </Box>
            <Box minWidth="180px" paddingLeft="28px" paddingRight="28px">
              {order.gift.brand && (
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px"
                  }}
                >
                  {order.gift.brand}
                </Typography>
              )}
              <Typography
                style={{
                  fontSize: "14px"
                }}
              >
                {order.gift.name}
              </Typography>
              <Typography style={{ fontSize: "14px" }}>{moment(order.date_ordered).format("MM/DD/YYYY")}</Typography>
              <Typography
                style={{
                  fontSize: "14px",
                  fontWeight: "bold"
                }}
              >
                {CURRENCY}
                {roundToDecimalPlaces(order.pricing.total_price_paid_converted, 2).split(".")[0]}.
                <span style={{ fontWeight: 500 }}>
                  {sup(roundToDecimalPlaces(order.pricing.total_price_paid_converted, 2).split(".").pop())}
                </span>
              </Typography>
              <Box paddingTop={"16px"}>
                {order.buyer_id !== process.env.REACT_APP_GUEST_CHECKOUT_USER_ID && (
                  <>
                    <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>From</Typography>
                    <Typography style={{ fontSize: "16px" }}>{order.buyer_nickname}</Typography>
                  </>
                )}
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: mappedStatus.color
                  }}
                >
                  {mappedStatus.label}
                </Typography>
              </Box>
              <Box display={"inline-flex"} flexDirection={"row"}>
                <img
                  src={`https://s2.googleusercontent.com/s2/favicons?domain_url=https://${order.channel_partner_data.channel_partner_url}`}
                  alt=""
                  style={{
                    alignSelf: "center",
                    width: "16px",
                    height: "16px",
                    borderRadius: "10px"
                  }}
                />
                <Typography variant="body2" style={{ marginLeft: 8 }}>
                  {order.channel_partner_data.channel_partner_store_name}
                </Typography>
              </Box>
              <Box paddingTop={"16px"}>
                <ActionButtons goTo={goTo} order={order} />
                {(order.claim_status ?? "").toLowerCase() && !order.canceled ? (
                  <PrimaryButtonV3
                    style={{
                      marginTop: "16px",
                      borderRadius: "24px",
                      fontSize: "14px",
                      minWidth: "150px",
                      height: "32px",
                      maxWidth: "150px",
                      fontWeight: 700
                    }}
                    handleClick={redeem}
                  >
                    {"Redeem"}
                  </PrimaryButtonV3>
                ) : null}
              </Box>
            </Box>
          </Box>
          <Divider orientation={"horizontal"} variant={"fullWidth"} style={{ marginTop: "23px" }} />
        </>
      )}
    </>
  );
}
