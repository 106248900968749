import * as sdk from "../sdk";

export const ORDER_STATUSES = {
  UNCLAIMED: "unclaimed",
  CLAIMED: "claimed",
  EXCHANGED: "exchanged",
  DECLINED: "declined",
  WITHDRAWN: "withdrawn",
  EXPIRED: "expired",
  ABORTED: "aborted",
  CANCELED: "canceled",
  UNKNOWN: "unknown",
  ACCEPTED: "accepted",
  PENDING: "pending",
  STORE_CREDIT: "store credit",
  ADDRESS_INVALID: "address invalid",
  PROCESSING: "processing",
  SHIPPED: "shipped",
  DELIVERED: "delivered",
};

export enum orderParty {
  Buyer = "buyer",
  Receiver = "receiver"
}

const colors: { gray: string; red: string; green: string } = {
  gray: "#B5B5B5",
  red: "#DD0808",
  green: "#11C773"
};

const data = {
  unclaimed: {
    color: colors.gray,
    label: "PENDING"
  },
  claimed: {
    color: colors.green,
    label: "GIFT ACCEPTED"
  },
  exchanged: {
    color: colors.green,
    label: "EXCHANGED"
  },
  declined: {
    color: colors.red,
    label: "DECLINED"
  },
  withdrawn: {
    color: colors.red,
    label: "WITHDRAWN"
  },
  expired: {
    color: colors.red,
    label: "EXPIRED"
  },
  aborted: {
    color: colors.green,
    label: "GIFT ACCEPTED"
  },
  canceled: {
    color: colors.red,
    label: "CANCELED"
  },
  unknown: {
    color: colors.red,
    label: "UNKNOWN"
  },
  accepted: {
    color: colors.green,
    label: "ACCEPTED"
  },
  pending: {
    color: colors.gray,
    label: "PENDING"
  },
  "store credit": {
    color: colors.green,
    label: "STORE CREDIT"
  }
};

export const getOrders = async (page: number, numOfItems: number, orderParty?: orderParty) => {
  try {
    return await sdk.getOrders(page, numOfItems, orderParty);
  } catch (error) {
    console.error("orderAction - ERROR FETCHING ORDERS");

    throw error;
  }
};

export const mapOrderStatus = (status: string, canceled: boolean): { label: string; color: string } | null => {
  return data[status.toLowerCase()] ?? data[ORDER_STATUSES.UNKNOWN];
};
